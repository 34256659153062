import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { useAuth } from './context';

import './App.scss';
import ThemeToggle from './components/ThemeToggle';
import { useState } from 'react';

function App() {  
  const [isDarkMode, setIsDarkMode] = useState(true);

  const handleThemeChange = (isDarkMode) => {
    setIsDarkMode(isDarkMode);
    document.querySelector('html')
    .classList
    .toggle('dark-mode');
  }

  return (
    <div className={`app ${isDarkMode ? 'app--isdarkmode' : ''}`}> 
      {/* <ThemeToggle isDarkMode={isDarkMode} handleThemeChange={handleThemeChange} /> */}
      <Outlet />    
    </div>
  );
}

export default App;
