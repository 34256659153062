import { useState } from "react";

import './TableBody.css';

const TableBody = ({ data, choice }) => {
  const [currentChoice, setCurrentChoice] = useState(choice);
  const courses = [...data.courses];
  const periods = [...new Set(courses.map(course => course.period))];

  const coursesPerPeriod = periods.map(period => {
    return courses.filter(course => course.period === period);
  });

  const createWorkPlaceLearning = () => {
    let currentPeriod = 1;
    const workPlaceLearning = [];
    coursesPerPeriod.forEach((courses) => {
      courses.forEach((course) => {
        if (course.period !== currentPeriod) {
          if(workPlaceLearning.length + 1 < course.period) {
          workPlaceLearning.push("");
          currentPeriod = course.period;
          }
        }
        if(course.programLine === "WorkplaceLearning") {
          workPlaceLearning.push(course);
        }
      });
    });
    while(workPlaceLearning.length < periods.length) {
      workPlaceLearning.push("");
    }
    const result = workPlaceLearning.map((course, index) => {
      if(typeof course === "object") {
      return (<td className="workPlaceLearning" key={index} colSpan={1}>{course.name}<div className="flex"><p>{`${course.hoursWeek} u/w`}</p><p>{`${course.studyPoints} sp`}</p></div></td>);
      } else {
        return (<td className="workPlaceLearning_empty" key={index} colSpan={1}>{}</td>);
      }
    });
    return result;
  }

  const createComputerProgramming = () => {
    let currentPeriod = 1;
    const computerProgramming = [];
    coursesPerPeriod.forEach((courses) => {
      courses.forEach((course) => {
        if (course.period !== currentPeriod) {
          if(computerProgramming.length + 1 < course.period) {
          computerProgramming.push("");
          currentPeriod = course.period;
          }
        }
        if(course.programLine === "ComputerProgramming") {
          computerProgramming.push(course);
        }
      });
    });
    if(choice === "IMD"){
      computerProgramming.splice(3);
    }
    while(computerProgramming.length < periods.length) {
      computerProgramming.push("");
    }
    const result = computerProgramming.map((course, index) => {
      if(typeof course === "object") {
      return (<td className="computerProgramming" key={index} colSpan={1}>{course.name}<div className="flex"><p>{`${course.hoursWeek} u/w`}</p><p>{`${course.studyPoints} sp`}</p></div></td>);
      } else {
        return (<td className="computerProgramming_empty" key={index} colSpan={1}>{}</td>);
      }
    });
    return result;
  }

  const createCreativeDesign_Development = () => {
    let currentPeriod = 1;
    const creativeDesign_Development = [];
    coursesPerPeriod.forEach((courses) => {
      courses.forEach((course) => {
        if (course.period !== currentPeriod) {
          if(creativeDesign_Development.length + 1 < course.period) {
          creativeDesign_Development.push("");
          currentPeriod = course.period;
          }
        }
        if(course.programLine === "CreativeDesign_Development") {
          creativeDesign_Development.push(course);
        }
      });
    });
    if(choice === "CAD"){
      creativeDesign_Development.splice(3);
    }
    while(creativeDesign_Development.length < periods.length) {
      creativeDesign_Development.push("");
    }
    const result = creativeDesign_Development.map((course, index) => {
      if(typeof course === "object") {
      return (<td className="creativeDesign_Development" key={index} colSpan={1}>{course.name}<div className="flex"><p>{`${course.hoursWeek} u/w`}</p><p>{`${course.studyPoints} sp`}</p></div></td>);
      } else {
        return (<td className="creativeDesign_Development_empty" key={index} colSpan={1}>{}</td>);
      }
    });
    return result;
  }

  const createAppliedInformationTechnology = () => {
    let currentPeriod = 1;
    const appliedInformationTechnology = [];
    coursesPerPeriod.forEach((courses) => {
      courses.forEach((course) => {
        if (course.period !== currentPeriod) {
          if(appliedInformationTechnology.length + 1 < course.period) {
          appliedInformationTechnology.push("");
          currentPeriod = course.period;
          }
        }
        if(course.programLine === "AppliedInformationTechnology") {
          appliedInformationTechnology.push(course);
        }
      });
    });
    while(appliedInformationTechnology.length < periods.length) {
      appliedInformationTechnology.push("");
    }
    const result = appliedInformationTechnology.map((course, index) => {
      if(typeof course === "object") {
      return (<td className="appliedInformationTechnology" key={index} colSpan={1}>{course.name}<div className="flex"><p>{`${course.hoursWeek} u/w`}</p><p>{`${course.studyPoints} sp`}</p></div></td>);
      } else {
        return (<td className="appliedInformationTechnology_empty" key={index} colSpan={1}>{}</td>);
      }
    });
    return result;
  }

  const createBusiness_Communication = () => {
    let currentPeriod = 1;
    const business_Communication = [];
    coursesPerPeriod.forEach((courses) => {
      courses.forEach((course) => {
        if (course.period !== currentPeriod) {
          if(business_Communication.length + 1 < course.period) {
          business_Communication.push("");
          currentPeriod = course.period;
          }
        }
        if(course.programLine === "Business_Communication") {
          business_Communication.push(course);
        }
      });
    });
    while(business_Communication.length < periods.length) {
      business_Communication.push("");
    }
    const result = business_Communication.map((course, index) => {
      if(typeof course === "object") {
      return (<td className="business_Communication" key={index} colSpan={1}>{course.name}<div className="flex"><p>{`${course.hoursWeek} u/w`}</p><p>{`${course.studyPoints} sp`}</p></div></td>);
      } else {
        return (<td className="business_Communication_empty" key={index} colSpan={1}>{}</td>);
      }
    });
    return result;
  }

  const hell = createBusiness_Communication();
  const information = createAppliedInformationTechnology();
  const design = createCreativeDesign_Development();
  const coding = createComputerProgramming();
  const work = createWorkPlaceLearning();

return (
      <tbody>
        <tr>
          {hell}
        </tr>
        <tr>
          {information}
        </tr>
        <tr>
          {design}
        </tr>
        <tr>
          {coding}
        </tr>
        <tr>
          {work}
        </tr>
      </tbody>
    )
};

export default TableBody