import { gql, useQuery } from '@apollo/client';
import { NavLink, useParams } from 'react-router-dom';
import { PostDetail } from '../components/posts';

const PostDetailPage = () => {

  const Post = gql`
query MyQuery ($id: ID!) {
  post (
    where: {
      id: $id
    }) {
    title
    body
    member {
      username
      profile {
        avatar
      }
    }
  }
}
`;

const { loading, error, data } = useQuery(Post, {
  variables: {
    id: useParams().postID
  }
});

const gqlResultAsJSX = () => {
  if(loading) return <p>Loading...</p>;
  if(error) return <p>{JSON.stringify(error)}</p>;

  return (
    <>
      <PostDetail data={data.post} />
    </>
  );
}

  return (
    <div className='container'>
    { 
      gqlResultAsJSX()
    }
    </div>
  );
};

export default PostDetailPage;