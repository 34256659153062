import { NavLink } from "react-router-dom"

const ResultList = ({data}) => {
  return (
    <>
    <div className="row">
          <div className="col-md-6">
            <h2>Members</h2>
            <ul>
              {data.members.map((member) => (
                <li key={member.id}>
                  {member.username}
                </li>
              ))}
            </ul>
          </div>
          <div className="col-md-6">
            <h2>Posts</h2>
            <ul>
              {data.posts.map((post) => (
                <li key={post.id}>
                  <NavLink to={`/posts/${post.id}`} end>{post.title}</NavLink>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <h2>Projects</h2>
            <ul>
              {data.projects.map((project) => (
                <li key={project.id}>
                  {project.name}
                </li>
              ))}
            </ul>
          </div>
          <div className="col-md-6">
            <h2>Blogs</h2>
            <ul>
              {data.blogs.map((blog) => (
                <li key={blog.id}>
                  <NavLink to={`/blogs/${blog.id}`} end>{blog.title}</NavLink>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <h2>Courses</h2>
            <ul>
              {data.courses.map((course) => (
                <li key={course.id}>
                  {course.name}
                </li>
              ))}
            </ul>
          </div>
          <div className="col-md-6">
            <h2>Portfolios</h2>
            <ul>
              {data.portfolios.map((portfolio) => (
                <li key={portfolio.id}>
                  <NavLink to={`/portfolios/${portfolio.id}`} end>{portfolio.name}</NavLink>
                </li>
              ))}
            </ul>
          </div>
        </div>
        </>
  )
}

export default ResultList;