import { CurriculumTable } from '../components/curriculum';
import { gql, useQuery } from '@apollo/client';

const Curriculum = gql`
query GetCurriculum {
  curriculum(where: {startYear: "2022"}) {
    endYear
    name
    startYear
    courses {
      description
      hoursWeek
      id
      name
      period
      programLine
      semester
      studyPoints
    }
  }
}`;


const CurriculumPage = () => {

    const { loading, error, data } = useQuery(Curriculum);

    const gqlResultAsJSX = () => {
      if(loading) return <p>Loading...</p>;
      if(error) return <p>{JSON.stringify(error)}</p>;
      
      return (<CurriculumTable data={data.curriculum} />);
    }
    return (
    <div className='container'>
    {
      gqlResultAsJSX()
    }
    </div>
    )
};

export default CurriculumPage;