import { useAuth } from "../context";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const AccountPage = () => {
  const { currentUser, login, signOut} = useAuth();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const navigate = useNavigate();

  const proccesLogin = async () => {
    await login(username, password);
    navigate("/account");
  }

  const handleSubmit = () => {
    proccesLogin();
  }

  if(!currentUser) {
    return <div className="container">
      <p>You are not logged in</p>
      <form>
        <label>Username:</label>
        <input type="text" value={username} name="username" onChange={ e => setUsername(e.target.value)} />
        <label>Password:</label>
        <input type="password" value={password} name="password" onChange={e => setPassword(e.target.value)} />
        <button onClick={handleSubmit}>Login</button>
      </form>
      </div>
  } else {
  return (
    <div className="container">
      <h1>Account Page</h1>
      <p>You are logged in as {currentUser.username}</p>
      <button onClick={() => signOut()}>Sign out</button>
    </div>
  );
  }
};

export default AccountPage;