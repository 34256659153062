import { gql, useQuery } from '@apollo/client';
import { NavLink, useParams } from 'react-router-dom';
import { PostList } from '../components/posts';

const BlogDetailPage = () => {

  const Blog = gql`
query MyQuery ($id: ID!) {
  blog (
    where: {
      id: $id
    }) {
    title
    description
    posts {
      title
      body
      id
      member {
        username
      }
    }
  }
}
`;

const { loading, error, data } = useQuery(Blog, {
  variables: {
    id: useParams().blogID
  }
});

  const gqlResultAsJSX = () => {
    if(loading) return <p>Loading...</p>;
    if(error) return <p>{JSON.stringify(error)}</p>;

    console.log(data.blog);

    return (
      <>
        <PostList blog={data.blog} />
      </>
    );
  }
  return (
    <div className='container'>
    <NavLink to='/blogs'> terug </NavLink>
    {
      gqlResultAsJSX()
    }
    </div>
  );
};

export default BlogDetailPage;