import { NavLink } from "react-router-dom";
import { Card, Col, Row, CardImg, CardText, CardTitle, CardBody } from "reactstrap";

import './PortfolioList.css';

const PortfolioList = ({ portfolios }) => {
  return (
    <Row xs={1} md={3} className="portfolio-grid">
      {portfolios.map((portfolio) => {
        return (
          <Col>
          <Card key={portfolio.id} className="card">
            <CardImg src={portfolio.projects[0].imageUrl} alt="project" top width="100%"></CardImg>
            <CardBody>
            <CardTitle
            tag="h3">
              <NavLink to={`/portfolios/${portfolio.id}`} end>
                {portfolio.name}
              </NavLink>
            </CardTitle>
            <CardText>{portfolio.projects[0].member.username}</CardText>
            </CardBody>
          </Card>
          </Col>
        )})}
    </Row>
  );
}

export default PortfolioList;
