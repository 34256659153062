import { TeamList } from '../components/team';
import { gql, useQuery } from '@apollo/client';

const Team = gql`
query MyQuery {
  members {
    email
    id
    role
    username
    profile {
      avatar
      firstname
      lastname
    }
  }
}
`;

const TeamPage = () => {
  const { loading, error, data } = useQuery(Team);

  const gqlResultAsJSX = () => {
    if(loading) return <p>Loading...</p>;
    if(error) return <p>{JSON.stringify(error)}</p>;
    
    return (<TeamList data={data} />);
  }
  return (
    <div className='container'>
    {
      gqlResultAsJSX()
    }
    </div>
  );
};

export default TeamPage;