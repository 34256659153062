import { NavLink } from "react-router-dom";
import { ListGroup, ListGroupItem } from "reactstrap";

import './PostList.css';

const PostList = ({ blog }) => {
  return (
    <div className="posts">
      <h1>{blog.title}</h1>
      <ListGroup className="post-list">
    {blog.posts.map((post) => {
      return (
        <ListGroupItem key={post.id} className="post">
          <NavLink to={`/posts/${post.id}`} end>
          <h3 className="post-title">{post.title}</h3>
          </NavLink>
          <p className="post-body">{post.body}</p>
          <p className="post-author">{post.member.username}</p>
        </ListGroupItem>
      )
    })}
    </ListGroup>
    </div>
  );
}

export default PostList;
