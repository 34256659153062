import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';

// React Router v6 imports
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";

// Custom compoments
import App from './app';

// Utilities
import { EducationPage, HomePage, CurriculumPage, PortfoliosPage, BlogPage, ServicesPage, TeamPage, AccountPage, BlogDetailPage, PostDetailPage, PortfolioDetailPage, SearchPage } from './app/pages';
import { AuthProvider, GraphCMSProvider } from './app/context';
import { AdminLayout, PublicLayout, UserLayout } from './app/components/layout';

ReactDOM.render(
  <GraphCMSProvider>
    <AuthProvider>
      <BrowserRouter>
        <Routes>
          <Route element={<App />}>
            <Route path="/" element={<PublicLayout />}>
            <Route path='home' element={<HomePage />} />
              <Route path="opleiding" element={<EducationPage />} />  
              <Route path="curriculum" element={<CurriculumPage />} /> 
              <Route path="portfolios" element={<PortfoliosPage />} />
              <Route path="portfolios/:portfolioID" element={<PortfolioDetailPage />} />
              <Route path="blogs" element={<BlogPage />} />
              <Route path="blogs/:blogID" element={<BlogDetailPage />} />
              <Route path="posts/:postID" element={<PostDetailPage />} />
              <Route path="services" element={<ServicesPage />} />
              <Route path="team" element={<TeamPage />} />
              <Route path="account" element={<AccountPage />} />
              <Route path='search' element={<SearchPage />} />
            </Route>            
            <Route path="user" element={<UserLayout />}>
            <Route path='home' element={<HomePage />} />
              <Route path="opleiding" element={<EducationPage />} />  
              <Route path="curriculum" element={<CurriculumPage />} /> 
              <Route path="portfolios" element={<PortfoliosPage />} />
              <Route path="portfolios/:portfolioID" element={<PortfolioDetailPage />} />
              <Route path="blogs" element={<BlogPage />} />
              <Route path="blogs/:blogID" element={<BlogDetailPage />} />
              <Route path="posts/:postID" element={<PostDetailPage />} />
              <Route path="services" element={<ServicesPage />} />
              <Route path="team" element={<TeamPage />} />
              <Route path="account" element={<AccountPage />} />
            </Route>
            <Route path="admin" element={<AdminLayout />}>
            <Route path='home' element={<HomePage />} />
              <Route path="opleiding" element={<EducationPage />} />  
              <Route path="curriculum" element={<CurriculumPage />} /> 
              <Route path="portfolios" element={<PortfoliosPage />} />
              <Route path="portfolios/:portfolioID" element={<PortfolioDetailPage />} />
              <Route path="blogs" element={<BlogPage />} />
              <Route path="blogs/:blogID" element={<BlogDetailPage />} />
              <Route path="posts/:postID" element={<PostDetailPage />} />
              <Route path="services" element={<ServicesPage />} />
              <Route path="team" element={<TeamPage />} />
              <Route path="account" element={<AccountPage />} />
            </Route>
            <Route
                path="*"
                element={
                  <main style={{ padding: "1rem" }}>
                    <p>There's nothing here!</p>
                  </main>
                }
              />
          </Route>
        </Routes>
      </BrowserRouter>   
    </AuthProvider> 
  </GraphCMSProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
