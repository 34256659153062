import { gql, useQuery } from "@apollo/client";
import SearchResults from "../components/search/SearchResult";

const Search = gql`
query MyQuery {
  blogs {
    id
    title
    description
  }
  courses {
    description
    name
    period
    programLine
    hoursWeek
    semester
  }
  members {
    email
    role
    username
  }
  portfolios {
    name
    id
  }
  posts {
    body
    id
    title
  }
  projects {
    imageUrl
    name
  }
}
`;

const SearchPage = () => {
  const { loading, error, data } = useQuery(Search);
  
  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;


  return (
    <div>
      <SearchResults data={data} />
    </div>
  );
}

export default SearchPage;