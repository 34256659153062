import { gql, useQuery } from '@apollo/client';
import { BlogList } from '../components/blog';

const Blog = gql`
query MyQuery {
  blogs {
    title
    description
    id
  }
}
`;

const BlogPage = () => {
  const { loading, error, data } = useQuery(Blog);

  const gqlResultAsJSX = () => {
    if(loading) return <p>Loading...</p>;
    if(error) return <p>{JSON.stringify(error)}</p>;
    
    return (
      <BlogList blogs={data.blogs} />
    );
  }
  return (
    <div className='container'>
    <h1>
      Blogs
    </h1>
    {
      gqlResultAsJSX()
    }
    </div>
  );
};

export default BlogPage;