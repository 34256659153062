import { Card, Col, Row, CardText, CardTitle, CardBody } from "reactstrap";

import './services.page.css';

const ServicesPage = () => {
  return (
    <div className="services container">
    <h1>Services</h1>
    <Row xs={2} md={3} className="services-grid">
          <Col>
          <Card key={1} className="card">
            <CardBody>
            <CardTitle
            tag="h3">
              Iets voor jou?
            </CardTitle>
            <CardText>Je hebt niet alleen een passie voor computers, STEM en IT, maar ook nog eens een creatief brein? Je wil flitsende en gebruiksvriendelijke websites en mobiele apps van 0 leren programmeren en bouwen? Dan is dit de geknipte opleiding voor jou.</CardText>
            </CardBody>
          </Card>
          </Col>
          <Col>
          <Card key={2} className="card">
            <CardBody>
            <CardTitle
            tag="h3">
              Wat zal je leren?
            </CardTitle>
            <CardText>In dit graduaat leer je programmeren en staat full-stack JavaScript development centraal. Lees hier welke programmalijnen- en inhouden aan bod komen tijdens de opleiding.</CardText>
            </CardBody>
          </Card>
          </Col>
          <Col>
          <Card key={3} className="card">
            <CardBody>
            <CardTitle
            tag="h3">
              Studieprogramma
            </CardTitle>
            <CardText>Bekijk het studieprogramma van de opleiding: vakken, modules, praktijk, projecten en aantal studiepunten.</CardText>
            </CardBody>
          </Card>
          </Col>
          <Col>
          <Card key={4} className="card">
            <CardBody>
            <CardTitle
            tag="h3">
              Trajecten op maat
            </CardTitle>
            <CardText>Je kan deze opleiding spreiden over drie jaar. Mogelijk heb je ook recht op vrijstellingen.</CardText>
            </CardBody>
          </Card>
          </Col>
          <Col>
          <Card key={5} className="card">
            <CardBody>
            <CardTitle
            tag="h3">
              Wat na je opleiding?
            </CardTitle>
            <CardText>Met dit graduaatsdiploma bouw je een carrière uit bij een gespecialiseerd digitaal bureau of op de IT-dienst van een (internationaal) bedrijf, een kmo of een non-profitorganisatie ... Ontdek je jobmogelijkheden en interessante aanvullende studies.</CardText>
            </CardBody>
          </Card>
          </Col>
          <Col>
          <Card key={6} className="card">
            <CardBody>
            <CardTitle
            tag="h3">
              Vragen? Stel ze
            </CardTitle>
            <CardText>Hier vind je de contactgegevens van de opleiding, de Dienst Studieadvies, de Dienst Studentenvoorzieningen (STUVO) en de Dienst Studentenadministratie.</CardText>
            </CardBody>
          </Card>
          </Col>
          <Col>
          <Card key={7} className="card">
            <CardBody>
            <CardTitle
            tag="h3">
              Online inschrijven en studiegeld
            </CardTitle>
            <CardText>Keuze gemaakt? Bekijk hier hoe je kan inschrijven (volledig online), welke documenten je nodig hebt en hoeveel het studiegeld bedraagt.</CardText>
            </CardBody>
          </Card>
          </Col>
          <Col>
          <Card key={8} className="card">
            <CardBody>
            <CardTitle
            tag="h3">
              Begeleiding op maat
            </CardTitle>
            <CardText>Wil je extra advies over studiekeuze, studiefinanciering, trajecten op maat of heb je nood aan een goed gesprek?</CardText>
            </CardBody>
          </Card>
          </Col>
    </Row>
    </div>
  );
};

export default ServicesPage;