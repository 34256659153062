import { useState } from "react";
import { Card, Col, Row, CardImg, CardText, CardTitle, CardBody } from "reactstrap";

import './TeamList.css';

const TeamList = ({ data }) => {

  const [members, setMembers] = useState(data.members);

  const handleClickEvent = (e) => {
    if(e.target.dataset.team === 'all') {
      setMembers([...data.members]);
    } else if (e.target.dataset.team === 'lecturers') {
      setMembers(data.members.filter(member => member.role === 'Lecturer'));
    } else if (e.target.dataset.team === 'students') {
      setMembers(data.members.filter(member => member.role === 'Student'));
    }
  }

  return(
    <>
    <h1>Team</h1>
    <div className="flex-container">
      <button className="all" data-team="all" onClick={handleClickEvent}>All team members</button>
      <button className="lecturers" data-team="lecturers" onClick={handleClickEvent}>Lecturers</button>
      <button className="students" data-team="students" onClick={handleClickEvent}>Students</button>
    </div>
      <Row xs={2} md={5} className="team-grid">
        {members.map(member => (
          <Col>
          <Card key={member.id} className="card">
            <CardImg src={member.profile.avatar} alt={member.profile.firstname} />
            <CardBody>
              <CardTitle
              tag="h2">
                {member.profile.firstname} {member.profile.lastname}
              </CardTitle>
              <CardText>{member.role}</CardText>
            </CardBody>
          </Card>
          </Col>
        ))}
      </Row>
    </>
  );
}

export default TeamList;