import { useEffect, useState } from 'react';
import SearchBar from './SearchBar';
import ResultList from './ResultList';

const SearchResults = ({ data }) => {

  const [searchValue, setSearchValue] = useState('');

   // eslint-disable-next-line react-hooks/rules-of-hooks
  const [searchResults, setSearchResults] = useState(data);

  // console.log(Object.values(data))

   // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    let d = {...data}
    d.members = d.members.filter((member) => 
      member.username.toLowerCase().includes(searchValue.toLowerCase()) || 
      member.email.toLowerCase().includes(searchValue.toLowerCase()));
    d.posts = d.posts.filter((post) => post.title.toLowerCase().includes(searchValue.toLowerCase()));
    d.projects = d.projects.filter((project) => project.name.toLowerCase().includes(searchValue.toLowerCase()));
    d.blogs = d.blogs.filter((blog) => blog.title.toLowerCase().includes(searchValue.toLowerCase()));
    d.courses = d.courses.filter((course) => course.name.toLowerCase().includes(searchValue.toLowerCase()));
    d.portfolios = d.portfolios.filter((portfolio) => portfolio.name.toLowerCase().includes(searchValue.toLowerCase()));
    setSearchResults(d);
  }, [searchValue, data]);

  const handleSearchChange = (e) => {
    setSearchValue(e);
  }

  return (
    <div>
      <div className="container">
      <h1>Search</h1>
        <SearchBar searchValue={searchValue} onSearchChange={handleSearchChange} />
        <ResultList data={searchResults} />
      </div>
    </div>
  );
}

export default SearchResults;