import { NavLink } from "react-router-dom";
import { ListGroup, ListGroupItem } from "reactstrap";

import './BlogList.css';

const BlogList = ({ blogs }) => {
  return (
    <ListGroup className="blogs">
    {blogs.map((blog) => {
      return (
        <ListGroupItem key={blog.id} className="blog">
          <NavLink to={`/blogs/${blog.id}`} end>
            <h3>{blog.title}</h3>
          </NavLink>
          <p>{blog.description}</p>
        </ListGroupItem>
      )
    })}
    </ListGroup>
  );
}

export default BlogList;
