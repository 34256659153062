import { gql, useQuery } from '@apollo/client';
import { NavLink } from 'react-router-dom';
import { BlogList } from '../components/blog';
import { PortfolioList } from '../components/portfolios';

import './home.page.css';

const HomeBlogs = gql`
query MyQuery {
  blogs(last: 3) {
    id
    description
    title
  }
  portfolios(last: 3) {
    name
    id
    projects {
      name
      id
      description
      imageUrl
      member {
        username
      }
    }
  }
}
`;

const HomePage = () => {
  const { loading, error, data } = useQuery(HomeBlogs);

  const gqlResultAsJSX = () => {
    if(loading) return <p>Loading...</p>;
    if(error) return <p>{JSON.stringify(error)}</p>;

    console.log(data);
    
    return (
      <div className='home-content'>
        <div className='home-intro'>
          <h1>Hello World, Welcome to our platform</h1>
          <p>
          Tijdens het Graduaat Programmeren leer je het zichtbare (front-end) en onzichtbare (backend) deel van web- en mobiele toepassingen ontwikkelen. Je wordt specialist in JavaScript, HTML, CSS en vult jouw skills aan met o.a. PHP, Python, UI/UX. Naast deze technische kant, vergaar je ook algemene ICT-skills. Je leert ook hoe digital agencies werken en wat jouw rol hierbinnen zal zijn. Na deze opleiding kan je aan de slag als front-end developer, full-stack JavaScript developer, PHP developer, Web Designer + Coder of CMS Themer.
          </p>
          <NavLink to='/curriculum'> Bekijk ons fantastich curriculum </NavLink>
        </div>
      <BlogList blogs={data.blogs} />
      <PortfolioList portfolios={data.portfolios} />
      </div>
    );
  }
  return (
    <div className='container'>
    {
      gqlResultAsJSX()
    }
    </div>
  );
};

export default HomePage;