import {Form, FormGroup, Label, Input} from 'reactstrap';

import { useCallback } from 'react';

const SearchBar = ({ searchValue, onSearchChange }) => {
  const handleSearchChange = useCallback((e) => {
    if (typeof onSearchChange === 'function') {
      onSearchChange(e.target.value);
    }
  },[onSearchChange])
return (
  <Form>
    <FormGroup>
      <Label for="search">Search</Label>
      <Input placeholder="search" type="text" name="search" id="search" value={searchValue} onChange={handleSearchChange} />
    </FormGroup>
  </Form>
  )
}

export default SearchBar;