
import './PostDetail.css';

const PostDetail = ({data}) => {

  return (
    <div className="container">
      <h1>{data.title}</h1>
      <div className="flex">
      <h3>{data.member.username}</h3>
      <img src={`${data.member.profile.avatar}`} alt={`${data.member.username}`}></img>
      </div>
      <p>{data.body}</p>
    </div>
  )

}

export default PostDetail;