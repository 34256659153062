import { Table } from "reactstrap";
import { useState } from "react";

import  "./CurriculumTable.css";

import TableHeader from "./TableHeader";
import TableBody from "./TableBody";

const CurriculumTable = ({ data }) => {

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [choice, setChoice] = useState("CAD");

  const handleChange = (event) => {
    setChoice(event.target.dataset.choice);
  }

  return (
    <>
    <h1>Graduaat Programeren</h1>
    <div className="flex-container">
      <button className="CAD" data-choice="CAD" onClick={handleChange}>Cloud Application Development</button>
      <p>of</p>
      <button className="IMD" data-choice="IMD" onClick={handleChange}>Interactive Media Development</button>
    </div>
    <Table>
      <TableHeader data={data} />
      <TableBody data={data} choice={choice} />
    </Table>
    </>
    )
}

export default CurriculumTable;
