const TableHeader = ({ data }) => {

  const createYears = () => {
    const years = [];
    for (let i = parseInt(data.startYear); i <= parseInt(data.endYear); i++) {
      years.push(i);
    }
    const result = [];

    for (let i = 0; i < years.length - 1; i++) {
      result.push(<th className="header_medium" key={i} colSpan={4}>{`${years[i]} - ${years[i+1]}`}</th>)
    }
    return result;
  }

  const createSemesters = () => {
    let semesters = [];
    data.courses.forEach((course) => {
      if (!semesters.includes(course.semester)) {
        semesters.push(course.semester);
      }
    });
    const result = semesters.map((semester, index) => {
      return (<th className="header_medium" key={index} colSpan={2}>{`semester ${semester}`}</th>);
    });
    return result;
  }

  const createPeriods = () => {
    let periods = [];
    data.courses.forEach((course) => {
      if (!periods.includes(course.period)) {
        periods.push(course.period);
      }
    });
    const result = periods.map((period, index) => {
      return (<th className="header_light" key={index} colSpan={1}>{`periode ${period}`}</th>);
    });
    return result;
  }

  const academicYears = createYears();
  const years = academicYears.map((year, index) => {
    return (<th className="header_dark" key={index} colSpan={4}>{`Jaar ${index + 1}`}</th>);
  });
  const semesters = createSemesters();
  const periods = createPeriods();

  return (
    <thead>
        <tr>
        {academicYears}
        </tr>
        <tr>
          {years}
        </tr>
        <tr>
        {semesters}
        </tr>
        <tr>
        {periods}
        </tr>      
      </thead>
  )

}

export default TableHeader;