import { Card, Col, Row, CardImg, CardText, CardTitle, CardBody } from "reactstrap";

const ProjectList = ({ portfolio }) => {
  return (
    <div className="portfolio">
      <h1>{portfolio.name}</h1>
      <h2>{portfolio.projects[0].member.username}</h2>
      <Row xs={1} md={4} className="portfolio-grid">
      {portfolio.projects.map((project) => {
        return (
          <Col>
          <Card key={project.id}>
            <CardImg src={project.imageUrl} alt="project" top width="100%"></CardImg>
            <CardBody>
            <CardTitle
            tag="h3">
              {project.title}
            </CardTitle>
            <CardText>{project.description}</CardText>
            </CardBody>
          </Card>
          </Col>
        )
      })}
      </Row>
    </div>
  );
}

export default ProjectList;
