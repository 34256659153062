
import './education.page.css';

const EducationPage = () => {
  return (
    <div className="education container">
      <h1>Opleiding</h1>
      <p className="education-intro">
      In het Graduaat Programmeren leer je programmeren en staat <strong>front-end web development</strong> centraal. Jouw lessen worden doorspekt met interactieve oefeningen. Je krijgt opdrachten om nieuwe leerstof snel onder de knie te krijgen. Werkplekleren en jouw stage zijn samen goed voor meer dan 1.200 uur aan praktijkervaring. Hierna ben je meer dan klaar voor de arbeidsmarkt.
      </p>
      <h2>
      Theoretische programmalijnen
      </h2>
      <h3 className="education-program-line">
      COMPUTER PROGRAMMING
      </h3>
      <p className='info'>
      Bereid je voor om een expert te worden in front-end web development. Je wordt expert in JavaScript en leert werken met React, Node.js, TypeScript, PHP en SQL. Om je optimaal klaar te stomen voor een job als programmeur, krijg je diverse gastcolleges van experten uit het werkveld en werk je aan authentieke cases. 
      </p>
      <h3 className="education-program-line">
      APPLIED INFORMATION TECHNOLOGY
      </h3>
      <p className='info'>
      Je verdiept je in de geschiedenis en de werking van het internet en ontdekt de logica achter programmeren. Daarnaast duik je in de informatie rond hardware, software en netwerken. Later staan onder meer databases, security en blockchain op het programma. 
      </p>
      <h3 className="education-program-line">
      UI/UX PROTOTYPING
      </h3>
      <p className='info'>
      In de vakken van deze programmalijn verdiep je je in de codeertalen HTML en CSS. Je leert werken met grafische software en gaat design thinking toepassen. Ook ga je aan de slag met CMS-systemen zoals WordPress. 
      </p>
      <h3 className="education-program-line">
      BUSINESS & COMMUNICATION
      </h3>
      <p className='info'>
      Om je toekomstige job echt te leren kennen, moet je het werkveld intrekken. Dat doe je in de programmalijn @Work. In het eerste jaar bezoek je een aantal digital agencies en voer je opdrachten voor hen uit. Je presenteert het resultaat vervolgens voor een interne en externe jury. In je tweede jaar ga je 10 weken lang werkplekleren in een digitaal bedrijf. Zo doe je praktische kennis op en ervaar je hoe het voelt om echt in team te werken. Tijdens een individueel afsluitend project dat je op de werkvloer uitvoert, verdiep je je bovendien in een onderwerp naar keuze.
      </p>
      <h3 className="education-program-line">
      WORKPLACE LEARNING @WORK
      </h3>
      <p className='info'>
      Bereid je voor om een expert te worden in front-end web development. Je wordt expert in JavaScript en leert werken met React, Node.js, TypeScript, PHP en SQL. Om je optimaal klaar te stomen voor een job als programmeur, krijg je diverse gastcolleges van experten uit het werkveld en werk je aan authentieke cases. 
      </p>

    </div>
  );
};

export default EducationPage;