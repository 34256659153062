import { gql, useQuery } from '@apollo/client';
import { NavLink, useParams } from 'react-router-dom';
import { ProjectList } from '../components/projects';

const PortfolioDetailPage = () => {

  const Portfolio = gql`
query MyQuery ($id: ID!) {
  portfolio (
    where: {
      id: $id
    }) {
    name
    projects {
      name
      imageUrl
      description
      id
      member {
        username
      }
    }
  }
}
`;

const { loading, error, data } = useQuery(Portfolio, {
  variables: {
    id: useParams().portfolioID
  }
});

  const gqlResultAsJSX = () => {
    if(loading) return <p>Loading...</p>;
    if(error) return <p>{JSON.stringify(error)}</p>;

    console.log(data.portfolio);

    return (
      <>
        <ProjectList portfolio={data.portfolio} />
      </>
    );
  }
  return (
    <div className='container'>
    <NavLink to='/portfolios'> terug </NavLink>
    {
      gqlResultAsJSX()
    }
    </div>
  );
};

export default PortfolioDetailPage;