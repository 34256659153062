import { gql, useQuery } from '@apollo/client';
import { PortfolioList } from '../components/portfolios';

const Portfolio = gql`
query MyQuery {
  portfolios {
    name
    id
    projects {
      name
      id
      description
      imageUrl
      member {
        username
      }
    }
  }
}
`;

const PortfoliosPage = () => {
  const { loading, error, data } = useQuery(Portfolio);

  const gqlResultAsJSX = () => {
    if(loading) return <p>Loading...</p>;
    if(error) return <p>{JSON.stringify(error)}</p>;
    
    return (
      <>
      <PortfolioList portfolios={data.portfolios} />
      </>
    );
  }
  return (
    <div className='container'>
    <h1>
      Portfolios
    </h1>
    {
      gqlResultAsJSX()
    }
    </div>
  );
};

export default PortfoliosPage;